import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CustomersApi } from '@idealsupply/ngclient-webservice-customers';
import { CustomerAccountRegistrationResult, ProfileApi } from '@idealsupply/ngclient-webservice-identity-server';
import { patchState, signalStore, withComputed } from '@ngrx/signals';
import { AuthenticationService } from 'authentication-data';
import { catchError, combineLatest, filter, map, of, switchMap, tap, throwError } from 'rxjs';
import { restoreModel, saveModel, updateModel, withModel, withModelLoader, withRequest } from 'state-data';
import { AccountNumberRegistrationAddedEvent } from './events/AccountNumberRegistrationAddedEvent';
import { AccountNumberRegistrationRemovedEvent } from './events/AccountNumberRegistrationRemovedEvent';
import { AccountNumberRegistration } from './models/AccountNumberRegistration';
import { AccountNumberRegistrationModel } from './models/AccountNumberRegistrationModel';
import { toAccountNameMap } from './util/toAccountNameMap';

export const AccountNumberRegistrationStore = signalStore(
  { providedIn: 'root' },
  withModel<AccountNumberRegistrationModel>({
    registrations: [],
    accountNames: {},
    userCustomerNumbers: [],
  }),
  withModelLoader(
    () => {
      const authService = inject(AuthenticationService);
      const profileApi = inject(ProfileApi);
      const customersApi = inject(CustomersApi);
      return toObservable(authService.user).pipe(
        switchMap((user) => {
          if (user) {
            return combineLatest([
              profileApi.getCustomerNumberRegistrations(),
              customersApi.getUsersCustomerAccounts(),
              of(user.customerNumbers),
            ]).pipe(
              map(([registrations, customerAccounts, userCustomerNumbers]) => ({
                registrations,
                accountNames: toAccountNameMap(customerAccounts),
                userCustomerNumbers,
              })),
            );
          } else {
            return of([] as AccountNumberRegistration[]);
          }
        }),
      );
    },
    { autoLoad: true },
  ),
  withRequest('updateAccountNames', (store) => {
    const customersApi = inject(CustomersApi);
    return (_, emit) => {
      return customersApi.getUsersCustomerAccounts().pipe(
        tap({
          next: (result) => {
            patchState(store, {
              model: {
                ...store.model(),
                accountNames: toAccountNameMap(result),
              },
            });
            patchState(store, saveModel());
          },
        }),
      );
    };
  }),
  withRequest('addAccountNumberRegistration', (store) => {
    const profileApi = inject(ProfileApi);
    return (accountNumber: string, emit) => {
      return of(accountNumber).pipe(
        tap((ac) => {
          const model = store.model();
          patchState(
            store,
            updateModel({
              ...model,
              registrations: [
                ...model.registrations,
                {
                  id: -1,
                  customerNumber: ac,
                  requestedDate: new Date().toISOString(),
                  status: 'pending',
                } as CustomerAccountRegistrationResult,
              ],
            }),
          );
        }),
        switchMap((customerNumber) =>
          profileApi.addCustomerNumberRegistration({ customerNumber }).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === 409) {
                console.warn('Account number was already registered', error);
                return of(error.error[0] as CustomerAccountRegistrationResult);
              }
              return throwError(() => error);
            }),
          ),
        ),
        tap({
          next: (result) => {
            const model = store.model();
            const registrations = [...model.registrations.filter((n) => n.customerNumber !== result.customerNumber), result];
            patchState(store, updateModel({ ...model, registrations }));
            patchState(store, saveModel());
            if (result.status === 'approved') {
              store.updateAccountNames();
            }
            emit.next(new AccountNumberRegistrationAddedEvent(result.customerNumber));
          },
          error: (error) => {
            patchState(store, restoreModel());
          },
        }),
      );
    };
  }),
  withRequest('removeAccountNumberRegistration', (store) => {
    const profileApi = inject(ProfileApi);
    return (accountNumber: string, emit) => {
      return of(accountNumber).pipe(
        map((ac) => store.model().registrations.find((n) => n.customerNumber === ac)),
        filter((reg) => !!reg),
        tap((reg) => {
          patchState(
            store,
            updateModel({
              ...store.model(),
              registrations: store.model().registrations.filter((n) => n.customerNumber !== reg!.customerNumber),
            }),
          );
        }),
        switchMap((reg) => profileApi.deleteCustomerNumberRegistration(reg!.customerNumber).pipe(map(() => reg))),
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            return of({
              customerNumber: accountNumber,
            } as CustomerAccountRegistrationResult);
          }
          return throwError(() => error);
        }),
        tap({
          next: (reg) => {
            patchState(store, saveModel());
            emit.next(new AccountNumberRegistrationRemovedEvent(reg!.customerNumber));
          },
          error: (error) => {
            patchState(store, restoreModel());
          },
        }),
      );
    };
  }),
  withComputed((store) => {
    const statusWeight = {
      denied: 0,
      pending: 1,
      approved: 2,
    };
    const registrations = computed(() => {
      const model = store.model();
      const registrations = model.registrations;
      const accountNames = model.accountNames;
      const userCustomerNumbers = model.userCustomerNumbers;
      return registrations
        .map(
          (r) =>
            ({
              ...r,
              accountName: accountNames[r.customerNumber] ?? null,
              active: userCustomerNumbers.includes(r.customerNumber),
            }) as AccountNumberRegistration,
        )
        .sort((a, b) => {
          const aStatus = statusWeight[a.status as 'pending' | 'approved' | 'denied'];
          const bStatus = statusWeight[b.status as 'pending' | 'approved' | 'denied'];
          if (aStatus < bStatus) return -1;
          if (aStatus > bStatus) return 1;
          const aDate = new Date(a.requestedDate).getTime();
          const bDate = new Date(b.requestedDate).getTime();
          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
          return a.customerNumber.localeCompare(b.customerNumber);
        });
    });

    const activeRegistrations = computed(() => registrations().filter((r) => r.active));
    const approvedButNotActiveRegistrations = computed(() => registrations().filter((r) => r.status === 'approved' && !r.active));
    const _isDesynchronized = computed(() => approvedButNotActiveRegistrations().length > 0);
    return {
      registrations,
      activeRegistrations,
      _isDesynchronized,
    };
  }),
);

export type AccountNumberRegistrationStore = InstanceType<typeof AccountNumberRegistrationStore>;
