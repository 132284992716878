import { inject } from '@angular/core';
import { ProfileApi } from '@idealsupply/ngclient-webservice-identity-server';
import { signalStoreFeature } from '@ngrx/signals';
import { tap } from 'rxjs';
import { withRequest } from 'state-data';
import { PasswordUpdatedEvent } from './events/PasswordUpdatedEvent';

export function withChangePassword() {
  return signalStoreFeature(
    withRequest('updatePassword', (store) => {
      const profileApi = inject(ProfileApi);
      return (password: string, emit) =>
        profileApi.updatePassword({ password }).pipe(
          tap(() => {
            emit.next(new PasswordUpdatedEvent());
          }),
        );
    }),
  );
}
