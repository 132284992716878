import { CustomerListItem } from '@idealsupply/ngclient-webservice-customers';

export function toAccountNameMap(customerAccounts: CustomerListItem[]) {
  const accountNames: Record<string, string> = {};
  customerAccounts.forEach((item) => {
    if (item.customerNumber && item.customerName) {
      accountNames[item.customerNumber] = item.customerName;
    }
  });
  return accountNames;
}
