import { computed, inject } from '@angular/core';
import { ProfileApi, ProfileDetailsResult } from '@idealsupply/ngclient-webservice-identity-server';
import { patchState, signalStoreFeature, withComputed, withMethods } from '@ngrx/signals';
import { of, switchMap, tap } from 'rxjs';
import { RequestMethod, RequestStatus, restoreModel, saveModel, updateModel, withModel, withModelLoader, withRequest } from 'state-data';
import { UserProfileBasicInfo } from './models/UserProfileBasicInfo';
import { UserProfileBasicInfoSavedEvent } from './events/UserProfileBasicInfoSavedEvent';
import { AuthenticationService } from 'authentication-data';

const initialProfile: ProfileDetailsResult = {
  id: '',
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  accountNumbers: [],
  roles: [],
  company: '',
};

export function withUserProfile() {
  return signalStoreFeature(
    withModel<ProfileDetailsResult>(initialProfile),
    withModelLoader(() => inject(ProfileApi).getProfile(), { autoLoad: true }),
    withMethods((store) => ({
      updateBasicInfo: (info: UserProfileBasicInfo) => {
        const newVal = { ...store.model(), ...info };
        patchState(store, updateModel<ProfileDetailsResult>(newVal));
        return store.model();
      },
      revert: () => {
        patchState(store, restoreModel());
        return store.model();
      },
    })),

    withRequest('saveBasicInfo', (store) => {
      const profileApi = inject(ProfileApi);
      return (_, emit) => {
        const model = store.model();
        return of({
          firstName: model.firstName,
          lastName: model.lastName,
          company: model.company,
          phone: model.phone,
        }).pipe(
          switchMap((model) => profileApi.updateProfile(model)),
          tap(() => patchState(store, saveModel())),
          tap(() => emit.next(new UserProfileBasicInfoSavedEvent())),
        );
      };
    }),
    withComputed((store) => {
      const authenticationService = inject(AuthenticationService);

      const basicInfo = computed(
        () =>
          ({
            company: store.model().company,
            phone: store.model().phone,
            firstName: store.model().firstName,
            lastName: store.model().lastName,
          }) as UserProfileBasicInfo,
      );

      const isLoading = computed(() => {
        const status = ((store as any).loadModel as RequestMethod<any>).status();
        return status === RequestStatus.INITIALIZING || status === RequestStatus.FETCHING;
      });

      const isBusy = computed(() => {
        const status = ((store as any).saveBasicInfo as RequestMethod<any>).status();
        const loading = isLoading();
        return loading || status === RequestStatus.FETCHING;
      });

      const adUsername = computed(() => authenticationService.user()?.adUsername);

      return {
        basicInfo,
        isLoading,
        isBusy,
        adUsername,
      };
    }),
  );
}
