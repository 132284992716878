import { inject } from '@angular/core';
import { ProfileApi } from '@idealsupply/ngclient-webservice-identity-server';
import { signalStoreFeature } from '@ngrx/signals';
import { tap } from 'rxjs';
import { withRequest } from 'state-data';
import { EmailChangeRequestSentEvent } from './events/EmailChangeRequestSentEvent';

export function withRequestEmailChange() {
  return signalStoreFeature(
    withRequest('requestEmailUpdate', (store) => {
      const profileApi = inject(ProfileApi);
      return (email: string, emit) =>
        profileApi.requestEmailUpdate({ email }).pipe(
          tap(() => {
            emit.next(new EmailChangeRequestSentEvent());
          }),
        );
    }),
  );
}
